<template>
  <CommonModal
    title="Thông báo"
    @updateOpen="setModalCreatePassword"
    :isOpen="openModal"
    @handleConfirm="handleCreatePassword"
    :isConfirmBtnLoading="isLoadingConfirm"
    :isConfirmBtnDisable="isDisabledBtn"
    :isCloseWhenConfirm="false"
    :isCancleBtnDisable="isLoadingConfirm"
    cancleTextBtn="Đóng"
    confirmTextBtn="Tạo mật khẩu"
    @handleCancle="clearForm"
    @handleHide="clearForm"
  >
    <div class="modalNotice mb-4" v-if="hasNotice">{{ noticeTitle }}</div>
    <div class="d-flex flex-column justify-content-between w-100 modalCreatePassword mb-lg-1">
      <div>
        <form ref="form" v-on:keyup.enter="handleCreatePassword">
          <b-form-group>
            <div class="modalRegisterLabel">
              <label for="newPassword">Mật khẩu</label>
            </div>
            <b-form-input
              name="password"
              type="password"
              ref="password"
              v-validate="{ required: true, min: 8, max: 16, regex: /^\S+$/ }"
              data-vv-as="Mật khẩu"
              aria-describedby="password-help-block"
              v-model="password"
              :class="{ input: true, 'is-danger': errors.has('password') }"
              id="password"
            ></b-form-input>
            <span
              v-if="errors.has('password') && hasErrorRule('password', 'required')"
              class="is-danger-text position-relative"
            >
              Vui lòng nhập Mật khẩu
            </span>
            <span
              v-else-if="errors.has('password') && hasErrorRule('password', 'regex')"
              class="is-danger-text position-relative"
            >
              Mật khẩu không được chứa khoảng trắng
            </span>
            <span v-else-if="errors.has('password')" class="is-danger-text position-relative">
              {{ errors.first('password') }}
            </span>
          </b-form-group>
          <!-- PassPort -->
          <b-form-group>
            <label for="confirmPassword">Nhập lại mật khẩu</label>
            <b-form-input
              name="confirmPassword"
              type="password"
              data-vv-as="Mật khẩu nhập lại"
              v-validate="'required|confirmed:password'"
              aria-describedby="password-help-block"
              :class="{ input: true, 'is-danger': errors.has('confirmPassword') }"
              v-model="confirmPassword"
            ></b-form-input>
            <span v-show="errors.has('confirmPassword')" class="is-danger-text position-relative">{{
              errors.first('confirmPassword')
            }}</span>
          </b-form-group>
        </form>
      </div>
    </div>
  </CommonModal>
</template>

<style lang="scss">
.modalNotice {
  font-size: 15px;
  font-style: italic;
  line-height: 1.47;
  color: #333333;
}
.modalCreatePassword {
  .form-control {
    width: 100%;
    height: 40px;
    border-radius: 3px;
    border: solid 1px #efefef;
    background-color: #f7f7f7;
    &:focus {
      color: #495057;
      border-color: #efefef;
      outline: 0;
      -webkit-box-shadow: none;
      box-shadow: none;
    }
  }
}
</style>

<script>
import CommonModal from '../CommonModal.vue';
import AccountService from '@/api/account';

export default {
  props: {
    onCreatePasswordSuccess: {
      type: Function
    },
    modalCreatePassword: {
      type: Boolean,
      default: false
    },
    hasNotice: {
      type: Boolean,
      default: true
    },
    noticeTitle: {
      type: String,
      default: 'Bạn cần tạo mật khẩu cho tài khoản để thực hiện yêu cầu này.'
    }
  },
  components: {
    CommonModal
  },
  mounted() {
    this.openModal = this.modalCreatePassword;
  },
  computed: {
    userProfile() {
      return this.$store.getters.getterUserProfile;
    },
    isDisabledBtn() {
      return (
        this.errors.has('password') ||
        !this.password ||
        this.errors.has('confirmPassword') ||
        !this.confirmPassword ||
        this.password != this.confirmPassword
      );
    },
    openModal: {
      get() {
        return this.modalCreatePassword;
      },
      set(newVal) {
        this.$emit('updateModal', newVal);
      }
    }
  },
  data() {
    return {
      isLoadingConfirm: false,
      password: null,
      confirmPassword: null
    };
  },
  methods: {
    hasErrorRule(field, rule) {
      return this.errors.items.find((item) => item.field == field && item.rule == rule);
    },
    setModalCreatePassword(newVal) {
      this.openModal = newVal;
    },
    handleCreatePassword() {
      if (this.errors.has('password') || this.errors.has('confirmPassword')) return;
      this.isLoadingConfirm = true;
      AccountService.createPassword(this.userProfile.id, { password: this.password })
        .then((resp) => {
          if (resp && resp.status == 204) {
            this.$store.commit('setUserProfile', {
              ...this.userProfile,
              hasPassword: true
            });
            this.$emit('onCreatePasswordSuccess');
            this.isLoadingConfirm = false;
            this.openModal = false;
          }
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          this.isLoadingConfirm = false;
        });
    },
    clearForm() {
      this.password = null;
      this.confirmPassword = null;
    }
  }
};
</script>
