<template>
  <div>
    <div class="section-header d-none d-lg-flex">
      <div class="header-title">Tài khoản thưởng</div>
      <div class="header-link">
        <router-link to="/tai-khoan/lich-su-giao-dich/"
          ><svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12">
            <g transform="translate(-1095 -280)">
              <rect width="12" height="12" transform="translate(1095 280)" fill="#fff" opacity="0" />
              <path
                d="M.152-4.632a.45.45,0,0,0,0,.637l5.5,5.549a.45.45,0,0,0,.637,0L7.036.812a.45.45,0,0,0,0-.637L2.593-4.313,7.036-8.8a.45.45,0,0,0,0-.637l-.742-.742a.45.45,0,0,0-.637,0Z"
                transform="translate(1096.98 290.313)"
                fill="#666"
              />
            </g>
          </svg>
          Lịch sử giao dịch</router-link
        >
      </div>
    </div>
    <hr class="d-none d-lg-block" />
    <div class="section-content">
      <div class="content-container method-container mb-3 mb-lg-0">
        <!-- <div class="content-title"></div> -->

        <div class="d-flex flex-row justify-content-between align-items-center mb-2">
          <div class="content-title mb-0">Chọn cách rút tiền</div>
          <a v-if="isMobile && selectedMethod.id" class="content-title-update mt-0" @click="onUnSelectPaymentMethod()"
            >THAY ĐỔi</a
          >
        </div>

        <b-card
          class="customCard"
          :class="{
            'mr-lg-3': selectedMethod && selectedMethod.id
          }"
        >
          <div
            v-for="method in paymentMethods"
            :key="'method_' + method.id"
            :class="{
              'payment-method': true,
              selected: method.id == selectedMethod.id,
              'disabled-method': method.handler !== 'wallet' && method.handler !== 'bank',
              'flex-wrap': method.handler === 'bank',
              customCard__item: true
            }"
            @click="selectMethod(method)"
            v-show="isMobile && selectedMethod.id ? method.id === selectedMethod.id : true"
          >
            <div :class="{ paymentContent: method.handler === 'bank' }" class="payment-detail align-items-center ">
              <div class="payment-image">
                <img width="40" height="40" :src="method.image_url" alt="" />
              </div>
              <div class="payment-info">
                <p class="payment-name">{{ method.name }}</p>
                <div v-if="method.handler === 'bank'">
                  Phí giao dịch 2%. Rút tối thiểu {{ formatPrice(minWithdraw.bank) }}
                </div>
                <div v-else>Miễn phí. Rút tối thiểu {{ formatPrice(minWithdraw.wallet) }}</div>
              </div>
            </div>
            <div class="w-100" v-if="method.handler === 'bank' && !!bankAccountNumber && !isEditBank">
              <hr />

              <div class="d-flex flex-row justify-content-between align-items-center mb-2">
                <div class="">Tài khoản nhận tiền</div>
                <a class="content-title-update mt-0" @click="onUnEditBankInfo(method)">THAY ĐỔi</a>
              </div>
              <div>
                <div>Ngân hàng: {{ textActiveBank }}</div>
                <div>Số TK: {{ bankAccountNumber }}</div>
                <div>Chủ TK: {{ bankAccountName }}</div>
              </div>
            </div>
            <div
              v-if="method.handler === 'bank' && method.id == selectedMethod.id && (isEditBank || !bankAccountNumber)"
              class="contentWidthDraw mt-lg-4 mt-3"
            >
              <p class="fontSize14 font-italic color-grey">
                Vui lòng nhập thông tin tài khoản ngân hàng nhận tiền thưởng.
              </p>
              <form ref="form" class="mt-2">
                <div class="row">
                  <div class="col-lg-12">
                    <!-- <p>Họ và tên</p> -->
                    <b-form-group class="mb-0">
                      <label>Ngân hàng</label>
                      <CommonSearchList
                        :listData="listBank"
                        @onChangeActive="handleBankSelect"
                        :defaultActive="activeBankData"
                        placeholder="Chọn ngân hàng"
                      />
                      <!-- <b-dropdown
                        id="dropdown-banks"
                        v-bind:text="textActiveBank"
                        class="dropdown-input-form m-0 form-control p-0"
                      >
                        <b-dropdown-item
                          v-for="(bank, index) in listBank"
                          :key="index"
                          :value="bank.name"
                          @click="handleBankSelect(bank)"
                          >{{ bank.short_name }}</b-dropdown-item
                        >
                      </b-dropdown> -->
                    </b-form-group>
                  </div>
                  <div class="col-lg-6">
                    <b-form-group class="mb-0">
                      <label>Tỉnh thành</label>

                      <CommonSearchList
                        :listData="listProvince"
                        @onChangeActive="handleProviceSelect"
                        :defaultActive="activeProvinceData"
                        placeholder="Vui lòng chọn"
                      />

                      <!--                       
                      <b-dropdown
                        id="dropdown-province"
                        v-bind:text="textActiveProvince"
                        v-model="provinceActive"
                        class="dropdown-input-form m-0 form-control p-0"
                      >
                        <b-dropdown-item
                          v-for="(item, index) in listProvince"
                          :key="index"
                          :value="item.name"
                          @click="handleProviceSelect(item)"
                          >{{ item.name }}</b-dropdown-item
                        >
                      </b-dropdown> -->
                    </b-form-group>
                  </div>
                  <div class="col-lg-6 pl-lg-2">
                    <b-form-group class="mb-3 mb-lg-0">
                      <label>Chi nhánh</label>
                      <b-form-input
                        placeholder="Tên chi nhánh"
                        v-validate="'required'"
                        class="customInputWithDraw form-control"
                        type="text"
                        v-model="bankBranch"
                        data-vv-as="Chi nhánh"
                        :class="{ input: true, 'is-danger': errors.has('bankBranch') }"
                        id="bankBranch"
                        name="bankBranch"
                      ></b-form-input>
                      <span v-show="errors.has('bankBranch')" class="is-danger-text">{{
                        errors.first('bankBranch')
                      }}</span>
                    </b-form-group>
                  </div>
                  <div class="col-lg-6">
                    <b-form-group>
                      <label>Số tài khoản</label>
                      <b-form-input
                        v-validate="'required'"
                        class="customInputWithDraw"
                        type="number"
                        v-model="bankAccountNumber"
                        data-vv-as="Số tài khoản"
                        :class="{ input: true, 'is-danger': errors.has('bankAccountNumber') }"
                        id="bankAccountNumber"
                        name="bankAccountNumber"
                      ></b-form-input>
                      <span v-show="errors.has('bankAccountNumber')" class="is-danger-text">{{
                        errors.first('bankAccountNumber')
                      }}</span>
                    </b-form-group>
                  </div>
                  <div class="col-lg-6 pl-lg-2">
                    <b-form-group>
                      <label>Chủ tài khoản</label>
                      <b-form-input
                        v-validate="'required'"
                        class="customInputWithDraw"
                        v-model="bankAccountName"
                        data-vv-as="Chủ tài khoản"
                        :class="{ input: true, 'is-danger': errors.has('bankAccountName') }"
                        id="bankAccountName"
                        name="bankAccountName"
                      ></b-form-input>
                      <span v-show="errors.has('bankAccountName')" class="is-danger-text">{{
                        errors.first('bankAccountName')
                      }}</span>
                    </b-form-group>
                  </div>
                  <div class="col-lg-6">
                    <b-form-group>
                      <label>Số CMND/CCCD</label>
                      <b-form-input
                        v-validate="{ required: true, regex: /^([0-9]{9}|[0-9]{12})$/ }"
                        class="customInputWithDraw"
                        v-model="passportId"
                        data-vv-as="Số CMND/CCCD"
                        :class="{ input: true, 'is-danger': errors.has('passportId') }"
                        id="passportId"
                        name="passportId"
                        type="text"
                      ></b-form-input>
                      <span
                        v-if="errors.has('passportId') && hasErrorRule('passportId', 'required')"
                        class="is-danger-text"
                        >Vui lòng nhập Số CMND/CCCD</span
                      >
                      <span
                        v-if="errors.has('passportId') && hasErrorRule('passportId', 'regex')"
                        class="is-danger-text"
                        >Số CMND/CCCD phải có 9 hoặc 12 số</span
                      >
                    </b-form-group>
                  </div>
                  <div class="col-lg-6 pl-lg-2">
                    <b-form-group
                      id="group-dob"
                      label="Ngày sinh"
                      label-for="input-dob"
                      class="modalUpdateInfoFormGroup"
                    >
                      <div class="justify-content-center position-relative inputDate">
                        <UIDateTimePickerCommon
                          :isMobile="isMobile"
                          :title="'Ngày sinh'"
                          :date_of_birth="dateOfBirth"
                          :maxDateDesktop="maxDateDesktop"
                          :maxDateMobile="maxDateMobile"
                          :minYear="1920"
                          :minDateDesktop="new CustomDate('1920')"
                          :defaultSelectValue="'20/01/2000'"
                          @handlerInputCalendar="handlerInputCalendar"
                        ></UIDateTimePickerCommon>
                        <span v-show="dateOfBirthError" class="is-danger-text">{{ dateOfBirthError }}</span>
                      </div>
                    </b-form-group>
                  </div>
                  <div class="col-sm-12">
                    <ul class="bank-notice">
                      <li>
                        Quý khách cần nhập chính xác các thông tin để quá trình nhận tiền thưởng không phát sinh lỗi.
                      </li>

                      <li>
                        Thời gian xử lý giao dịch sẽ thực hiện trong khoảng 3-5 ngày làm việc.
                      </li>
                    </ul>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </b-card>
      </div>
      <div id="idReward"></div>
      <div
        v-if="selectedMethod && selectedMethod.id"
        class="content-container value-container"
        :class="{ paddingKeyboad: isApp }"
      >
        <div class="content-title">Số tiền rút</div>
        <b-card class="text-center">
          <div class="d-flex d-md-none mb-2 mt-1" style="justify-content: space-between">
            <p class="d-inline-block d-md-none text-left">Số dư: {{ formatPrice(rewardBalance, ' đ') }}</p>
            <a @click="handlerWithdrawAll()" class="d-inline-block payment-title mb-lg-3 color-black-1 pointer"
              >Rút hết</a
            >
          </div>
          <b-form-input
            id="deposit_amount"
            v-model="paymentValue"
            placeholder="Nhập số tiền rút"
            :state="invalidPaymentValue"
            aria-describedby="deposit-amount-feedback"
            trim
            :maxlength="11"
            :formatter="formatPaymentValue"
            @click="handlerScroll"
            inputmode="numeric"
          ></b-form-input>
          <b-form-invalid-feedback id="deposit-amount-feedback">
            {{ invalidPaymentValueMsg }}
          </b-form-invalid-feedback>

          <div class="content-payment mt-2">
            <div class="d-none d-md-block" style="justify-content: space-between">
              <p class="d-inline-block d-md-none text-left">Số dư: {{ formatPrice(rewardBalance, ' đ') }}</p>
              <a @click="handlerWithdrawAll()" class="d-inline-block payment-title mb-lg-3 color-black-1 pointer"
                >Rút hết</a
              >
            </div>
            <div class="groupInfo">
              <div class="groupInfo__item">
                <span>Tiền rút</span>
                <span>{{ formatPrice(convertPaymentToNumber) }}</span>
              </div>
              <div class="groupInfo__item">
                <span>Phí giao dịch ({{ transactionFee[selectedMethod.handler] }}%)</span>

                <span>{{ formatPrice(Math.ceil(convertPaymentToNumber * compTransactionFee)) }}</span>
              </div>
              <hr />
              <div class="groupInfo__item font-weight-bold">
                <span>Thực nhận</span>
                <span>{{
                  formatPrice(convertPaymentToNumber - Math.ceil(convertPaymentToNumber * compTransactionFee))
                }}</span>
              </div>
            </div>

            <button class="btn btn-payment text-uppercase" :disabled="buttonDisabled" @click="createDeposit()">
              Thực hiện
            </button>
          </div>
        </b-card>
      </div>
    </div>
    <modal-verify-password
      @updateVerifySuccess="updateVerifySuccess"
      @updateModalVerify="updateModalVerify"
      :modalVerify="modalVerify"
      :title="'Xác thực rút tiền'"
      :titleBtn="'Xác thực'"
      :titleDescription="'Vui lòng nhập mật khẩu để xác thực và thực hiện rút tiền.'"
    />
    <ModalCreatePassword
      :modalCreatePassword="modalCreatePassword"
      @onCreatePasswordSuccess="onCreatePasswordSuccess"
      @updateModal="onUpdateModalCreatePassword"
    />
  </div>
</template>

<script>
import ModalVerifyPassword from '@/components/ModalVerifyPassword';
import ModalCreatePassword from './ModalCreatePassword';
import UIDateTimePickerCommon from '../../components/UI/DateTimePicker/UIDateTimePickerCommon';
import PaymentService from '@/api/payment';
import { PaymentMethodWithdraw } from '@/api/mock/enum';
import { ErrorWithdrawService } from '@/api/mock/errors';
import BankAppService from '@/api/bank';
import CommonSearchList from '../CommonSearchList.vue';
import { mapGetters } from 'vuex';

const Swal = require('sweetalert2');

export default {
  layouts: 'Deposit',
  components: { ModalVerifyPassword, ModalCreatePassword, UIDateTimePickerCommon, CommonSearchList },
  data() {
    return {
      selectedMethod: {
        id: null,
        fee: 0
      },
      listTitle: PaymentMethodWithdraw,
      modalVerify: false,
      paymentMethods: [],
      paymentValue: null,
      objNeedToPayment: {},
      invalidPaymentValue: null,
      invalidPaymentValueMsg: '',
      textActiveBank: '',
      bankActive: '',
      listBank: [],
      textActiveProvince: '',
      provinceActive: '',
      listProvince: [],
      bankBranch: '',
      bankAccountNumber: '',
      bankAccountName: '',
      // minBank: 200000,
      // minWithdraw: 10000,
      minWithdraw: {
        bank: 100000,
        wallet: 10000
      },
      transactionFee: {
        bank: 2,
        wallet: 0
      },
      modalCreatePassword: false,
      passportId: null,
      dateOfBirth: null,
      dateOfBirthError: null,
      isEditBank: false
    };
  },
  beforeCreate() {
    if (Object.keys(this.$route.query).length > 0) {
      PaymentService.verifyPayment(this.$route.query)
        .then((res) => {
          if (res.data.status == true) {
            this.$router.push('/tai-khoan/lich-su-nap-tien/' + res.data.data.display_deposit_id);
          } else {
            this.$router.replace(this.$route.path);
          }
        })
        .catch(() => {
          this.$router.replace(this.$route.path);
        });
    }
  },
  created() {
    this.getPaymentMethods();
    this.getAllBank();
    this.getProvince();

    this.bankActive = this.userInfo.bank_id;
    this.provinceActive = this.userInfo.province_id;
    this.bankAccountNumber = this.userInfo.bank_account_number;
    this.bankAccountName = this.userInfo.bank_account_name;
    this.bankBranch = this.userInfo.bank_branch;
    this.dateOfBirth = this.userInfo.date_of_birth;
    this.passportId = this.userInfo.passport;
  },
  watch: {
    userInfo: function(newVal, oldVal) {
      if (newVal != oldVal) {
        this.bankActive = this.userInfo.bank_id;
        this.provinceActive = this.userInfo.province_id;
        this.bankAccountNumber = this.userInfo.bank_account_number;
        this.bankAccountName = this.userInfo.bank_account_name;
        this.bankBranch = this.userInfo.bank_branch;
        this.dateOfBirth = this.userInfo.date_of_birth;
        this.passportId = this.userInfo.passport;
      }
    },
    selectedMethod: function(value) {
      if (value?.method !== 'bank') {
        this.isEditBank = false;
      }
    }
  },
  mounted() {},
  methods: {
    hasErrorRule(field, rule) {
      return this.errors.items.find((item) => item.field == field && item.rule == rule);
    },
    handlerInputCalendar(newVal) {
      this.dateOfBirth = newVal;
      this.dateOfBirthError = newVal ? null : 'Vui lòng nhập ngày sinh';
    },
    onUpdateModalCreatePassword(newVal) {
      this.modalCreatePassword = newVal;
    },
    handlerScroll() {
      // location.href = '#idReward';
      // if (this.isOnlyMobile) {
      //   setTimeout(() => {
      //     document.getElementById('idReward').scrollIntoView({
      //       behavior: 'smooth'
      //     });
      //   }, 300);
      // }
    },
    updateModalVerify(value) {
      this.modalVerify = value;
    },
    handleBankSelect(obj) {
      this.textActiveBank = obj.short_name;
      if (this.bankActive !== obj.id) {
        this.bankAccountNumber = '';
        this.bankAccountName = '';
        this.bankBranch = '';
      }

      this.bankActive = obj.id;

      this.activeBankData = { ...obj };
    },
    getAllBank() {
      BankAppService.getAllBank()
        .then((resp) => {
          this.listBank = resp.data.map((item) => ({
            ...item,
            key: item.id,
            value: `${item.name} (${item.short_name})`
          }));

          if (this.userInfo.bank_id) {
            const foundBankData = this.listBank.find((item) => item.id === this.userInfo.bank_id);
            if (foundBankData) {
              this.activeBankData = foundBankData;
              this.textActiveBank = foundBankData.short_name;
              this.bankActive = foundBankData.id;
            }
          } else {
            this.textActiveBank = this.listBank[0].short_name;
            this.bankActive = this.listBank[0].id;
          }
        })
        .catch((e) => {
          console.error(e);
        });
    },
    handleProviceSelect(obj) {
      if (this.provinceActive !== obj.id) {
        this.bankBranch = '';
      }
      this.provinceActive = obj.id;
      this.textActiveProvince = obj.name;

      this.activeProvinceData = { ...obj };
    },
    getProvince() {
      BankAppService.getAllProvince()
        .then((resp) => {
          this.listProvince = resp.data.map((item) => ({
            ...item,
            key: item.id,
            value: item.name
          }));

          if (this.userInfo.province_id) {
            const foundProvinceData = this.listProvince.find((item) => item.id === this.userInfo.province_id);
            if (foundProvinceData) {
              this.activeProvinceData = foundProvinceData;
            }
          } else {
            this.textActiveProvince = this.listProvince[0].name;
            this.provinceActive = this.listProvince[0].id;
          }
        })
        .catch((e) => {
          console.error(e);
        });
    },
    selectMethod(method = {}) {
      if (method.handler == 'bank' && this.rewardBalance < this.minWithdraw.bank) {
        Swal.fire({
          icon: 'info',
          title: `Bạn cần có tối thiểu ${this.formatPrice(
            this.minWithdraw.bank
          )} trong tài khoản thưởng để thực hiện rút tiền về tài khoản ngân hàng.`,
          timer: 2000,
          showConfirmButton: false
        });
        return;
      }
      if (method.handler === 'wallet' || method.handler === 'bank') {
        this.selectedMethod = method;
      }
    },
    handlerWithdrawAll() {
      if (this.rewardBalance < this.compMinWithdraw) {
        Swal.fire({
          icon: 'info',
          title: `Bạn cần có tối thiểu ${this.formatPrice(
            this.compMinWithdraw
          )} trong tài khoản thưởng để thực hiện rút tiền.`,
          timer: 2000,
          showConfirmButton: false
        });
        return;
      }
      this.paymentValue = this.formatPaymentValue(this.rewardBalance);
    },
    formatNumberFromString(string) {
      const number = string.split('.').join('');
      if (isNaN(parseFloat(number))) {
        return null;
      }
      return parseFloat(number);
    },
    getPaymentMethods() {
      PaymentService.getPaymentMethods()
        .then((res) => {
          if (res.data.status == true) {
            this.paymentMethods = [...res.data.data];
            this.paymentMethods = this.paymentMethods
              .map((m) => {
                let obj = Object.assign({}, m);
                let item = this.listTitle.find((f) => f.id === m.id) || obj;
                obj.name = item.name || obj.name;
                obj.image_url = item.image_url || obj.image_url;
                return obj;
              })
              .filter((payment) => payment.allow_withdraw == 1)
              .filter((payment) => {
                let config = this.getterConfig;
                let allowMethod = true;
                if (config && config.exclude_payment_methods) {
                  allowMethod = !config.exclude_payment_methods.includes(payment.id);
                }
                return allowMethod;
              })
              .sort((a, b) => a.withdraw_priority - b.withdraw_priority);

            // this.selectedMethod = this.paymentMethods[0] || {
            //   id: null,
            //   fee: 0
            // };
          }
        })
        .catch((e) => {
          console.error(e);
        });
    },
    formatPaymentValue(value) {
      this.invalidPaymentValue = null;
      this.invalidPaymentValueMsg = '';
      if (value != '') {
        value = this.formatPrice(this.formatNumberFromString(value.toString().trim()), '');
      }
      return value;
    },
    createDeposit() {
      // Validate number
      let paymentValue = this.formatNumberFromString(this.paymentValue || '0');
      if (paymentValue == 0 || isNaN(paymentValue)) {
        this.invalidPaymentValue = false;
        this.invalidPaymentValueMsg = 'Vui lòng nhập số tiền cần rút.';
        return;
      }
      // Validate payment method
      if (this.selectedMethod == null) {
        this.invalidPaymentValue = false;
        this.invalidPaymentValueMsg = 'Vui lòng chọn phương thức thanh toán';

        return;
      }

      // let results = false;
      if (this.selectedMethod.handler === 'bank') {
        if (!this.bankActive) {
          Swal.fire({
            icon: 'error',
            title: 'Vui lòng chọn ngân hàng',
            timer: 2000
          });
          return;
        }
        if (!this.provinceActive) {
          Swal.fire({
            icon: 'error',
            title: 'Vui lòng chọn tỉnh thành',
            timer: 2000
          });
          return;
        }
        if (!this.dateOfBirth) {
          this.dateOfBirthError = 'Vui lòng nhập ngày sinh';
          return;
        }
        if (this.rewardBalance < this.compMinWithdraw) {
          Swal.fire({
            icon: 'info',
            title: `Bạn cần có tối thiểu ${this.formatPrice(
              this.compMinWithdraw
            )} trong tài khoản thưởng để thực hiện rút tiền về tài khoản ngân hàng.`,
            timer: 2000,
            showConfirmButton: false
          });
          return;
        }
        this.$validator.validateAll().then((valid) => {
          if (valid) {
            let payment_2 = {
              bank_id: this.bankActive,
              province_id: this.provinceActive,
              bank_account_number: this.bankAccountNumber,
              bank_branch: this.bankBranch,
              bank_account_name: this.bankAccountName,
              passport: this.passportId,
              date_of_birth: this.dateOfBirth
            };
            this.handlerBeforeVerify(paymentValue, payment_2);
            // this.onSumit(paymentValue, payment_2);
          }
        });
      } else {
        if (this.rewardBalance < this.compMinWithdraw) {
          Swal.fire({
            icon: 'info',
            title: `Bạn cần có tối thiểu ${this.formatPrice(
              this.compMinWithdraw
            )} trong tài khoản thưởng để thực hiện rút tiền.`,
            timer: 2000,
            showConfirmButton: false
          });
          return;
        }
        // this.handlerBeforeVerify(paymentValue);
        this.onSumit({ paymentValue });
      }
    },
    handlerBeforeVerify(paymentValue, payment) {
      this.objNeedToPayment = { paymentValue, payment };
      if (this.hasPassword) this.modalVerify = true;
      else this.modalCreatePassword = true;
    },
    onCreatePasswordSuccess() {
      this.onSumit(this.objNeedToPayment);
      this.modalCreatePassword = false;
    },
    updateVerifySuccess(value) {
      if (value.data === 'actived') {
        this.$store.getters.getterInputPasswordAgain ? this.onSumit(this.objNeedToPayment) : (this.modalVerify = false);
      }
    },
    onSumit({ paymentValue, payment }) {
      const accountId = this.$store.getters.getterAccountId;
      let data = {
        account_id: accountId,
        amount: paymentValue,
        payment_id: this.selectedMethod.id,
        ...payment
      };

      PaymentService.requestWithdraw(data)
        .then((res) => {
          const result = res.data;
          if (result.status) {
            let title =
              this.selectedMethod.handler === 'bank'
                ? 'Hệ thống đã ghi nhận yêu cầu rút tiền về ngân hàng của bạn.Thời gian giao dịch sẽ thực hiện trong khoảng 3-5 ngày làm việc.'
                : 'Rút tiền thành công';
            Swal.fire({
              icon: 'success',
              title,
              timer: 2000,
              showConfirmButton: false
            }).then(() => {
              const { deposit_wallet, withdraw_wallet } = result.data;
              this.rewardBalance = withdraw_wallet;
              this.depositBalance = deposit_wallet;
              this.paymentValue = null;
              const previousData = this.userInfo;
              this.$store.commit('setUserInfo', {
                ...previousData,
                bank_id: this.bankActive,
                province_id: this.provinceActive,
                bank_account_number: this.bankAccountNumber,
                bank_account_name: this.bankAccountName,
                bank_branch: this.bankBranch,
                passport: this.passportId.trim(),
                date_of_birth: this.dateOfBirth
              });
            });
          } else {
            Swal.fire({
              icon: 'error',
              title: result.data.message,
              showConfirmButton: true,
              timer: 2000
            }).then(() => {
              this.paymentValue = null;
            });
          }
        })
        .catch((e) => {
          let code = e.response.data.data.code;
          let error =
            ErrorWithdrawService.find((p) => p.key === code) || ErrorWithdrawService[ErrorWithdrawService.length - 1];
          Swal.fire({
            icon: 'error',
            title: `${error.message}`,
            showConfirmButton: false,
            timer: 3000
          });
        })
        .finally(() => {
          this.$store.commit('setInputPasswordAgain', '');
          this.objNeedToPayment = {};
        });
    },
    onUnSelectPaymentMethod() {
      this.selectedMethod = {
        id: null,
        fee: 0
      };
    },
    onUnEditBankInfo(method) {
      if (method.handler == 'bank' && this.rewardBalance >= this.minWithdraw.bank) {
        this.isEditBank = true;
        return;
      }

      this.selectMethod(method);
    }
  },
  computed: {
    ...mapGetters('partner', ['getterConfig']),
    maxDateMobile() {
      var d = new CustomDate(),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear() - 18;

      if (month.length < 2) month = '0' + month;
      if (day.length < 2) day = '0' + day;
      return [year, month, day].join('-');
    },
    maxDateDesktop() {
      let d = new CustomDate();
      return new CustomDate(d.getFullYear() - 18, d.getMonth(), d.getDate());
    },
    isMobile() {
      return window.innerWidth <= this.getMobileThresshold();
    },
    hasPassword() {
      return (
        this.$store.getters.getterUserProfile.hasPassword ||
        typeof this.$store.getters.getterUserProfile.hasPassword === 'undefined'
      );
    },
    isOnlyMobile() {
      return this.$store.getters.getterIsOnlyMobile;
    },
    isApp() {
      return this.$store.getters.getterIsApp;
    },
    userInfo() {
      return this.$store.getters.getterUserInfo;
    },
    buttonDisabled: {
      get() {
        return !(
          this.withdrawAmount >= this.compMinWithdraw &&
          this.selectedMethod.id !== null &&
          this.rewardBalance >= this.withdrawAmount
        );
      }
    },
    withdrawAmount: {
      get() {
        const value = this.paymentValue ? this.paymentValue : '';
        return value.replace(/\./g, '') - 0;
      }
    },
    compMinWithdraw() {
      return this.minWithdraw[this.selectedMethod.handler] || 0;
    },

    compTransactionFee() {
      if (this.transactionFee[this.selectedMethod.handler]) {
        return this.transactionFee[this.selectedMethod.handler] / 100;
      }
      return 0;
    },
    convertPaymentToNumber() {
      return this.formatNumberFromString(this.paymentValue || '0');
    }
  }
};
</script>
<style lang="scss" scoped>
.disabled-method {
  opacity: 0.5;
}
.btn-payment {
  height: 40px !important;
  font-size: 0.9375rem !important;
}

.payment-title {
  color: #00509d !important;
  text-transform: uppercase;
}
.bank-notice {
  font-size: 14px;
  font-style: italic;
  color: $primaryColor;
  list-style: disc;
  padding-left: 20px;
}

.groupInfo {
  &__item {
    display: flex;
    justify-content: space-between;
  }
}
.section-content {
  // .card-body {
  //   padding: 15px !important;
  // }
  @media screen and (max-width: map-get($grid-breakpoints, 'lg')) {
    .content-container {
      padding: 0 !important;
    }
  }
}
</style>
