<template>
  <div class="mb-3" ref="wrapperRef">
    <div class="mDropdown">
      <div class="position-relative">
        <a @click="openModal('dropdownSelectIdols')" class="btnDropdown customBackground pointer w-100  mb-2">
          <span v-if="itemActived.value">{{ itemActived.value }}</span>
          <span v-else class="text-muted">{{ placeholder }}</span>
        </a>
        <div class="modals" :class="{ active: isOpen }">
          <div tabindex="0" class="modal-inner" :ref="'dropdownSelectIdols'">
            <div>
              <div class="p-2">
                <b-form-input
                  autocomplete="off"
                  v-model="searchInput"
                  id="searchIdols"
                  type="text"
                  class="customInput "
                  :placeholder="placeholderSearch"
                ></b-form-input>
              </div>
              <div class="text-center py-1 pb-2" v-if="searchList && searchList.length == 0">
                <p>{{ noRecordFound }}</p>
              </div>
              <div v-else class="p-2 listItem" :style="{ maxHeight: maxHeightList }">
                <div v-for="item in searchList" :key="item.key">
                  <div @click="handlerItem(item)" class="d-flex align-items-center boxItemNotify">
                    <p class="color-black">{{ item.value }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  name: 'CommonSearchList',
  data() {
    return {
      itemActived: {},
      isOpen: false,
      search: ''
    };
  },
  props: {
    isLoading: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: ''
    },
    placeholderSearch: {
      type: String,
      default: 'Tìm kiếm'
    },
    noRecordFound: {
      type: String,
      default: 'Không tìm thấy'
    },
    listData: {
      type: Array,
      default: () => [
        {
          key: '',
          value: ''
        }
      ]
    },
    defaultActive: {
      type: Object,
      default: null
    },
    maxHeightList: {
      type: String,
      default: '200px'
    }
  },
  computed: {
    searchInput: {
      get() {
        return this.search;
      },
      set(value) {
        this.search = value;
      }
    },
    searchList() {
      return (
        this.listData?.filter((item) => item.value?.toLowerCase().includes(this.searchInput?.trim()?.toLowerCase())) ||
        []
      );
    }
  },
  created() {},
  mounted() {
    if (this.defaultActive) {
      this.$set(this, 'itemActived', this.defaultActive);
    }
  },
  beforeDestroy() {},
  methods: {
    handleModal(value) {
      this.isOpen = value;
    },
    openModal(e) {
      this.isOpen = true;
      this.$refs[e].focus();
    },
    closeModal() {
      this.isOpen = false;
    },
    handlerItem(item) {
      this.closeModal();
      this.$set(this, 'itemActived', item);
    },

    handleClickOutside(event) {
      if (this.isOpen) {
        if (this.$refs.wrapperRef && !this.$refs.wrapperRef.contains(event.target)) {
          this.closeModal();
        }
      }
    }
  },
  watch: {
    isOpen: function(value) {
      // if (!this.isMobileScreen) {
      if (value) {
        document.addEventListener('click', this.handleClickOutside, false);
      } else {
        document.removeEventListener('click', this.handleClickOutside, false);
      }
      // }
    },
    itemActived: function(value) {
      this.$emit('onChangeActive', { ...value });
    },
    defaultActive: function(value) {
      if (value?.key !== this.itemActived?.key) {
        this.$set(this, 'itemActived', this.defaultActive);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.btnDropdown {
  color: #333333;
  padding: 10px 15px;

  border: solid 1px #efefef;

  position: relative;
  display: flex;
  border-radius: 3px;
  align-items: center;
  transition: 0.3s all;
  height: 40px;

  &:after {
    content: '';
    position: absolute;
    right: 16px;
    display: inline-block;
    margin-top: 0;
    width: 8px;
    height: 8px;
    border-top: 2px solid #333 !important;
    border-right: 2px solid #333;
    border-left: none;
    transform: rotate(135deg) translate(0%, 100%);
    top: 50%;
    right: 10px;
  }
}
.modals {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  z-index: 1;
  transform: scale(0);
  transition: ease opacity 0.3s, ease transform 0s 0.3s;

  &.active {
    opacity: 1;
    transform: scale(1);
    transition: ease opacity 0.3s, ease transform 0s;
  }
}

.modal-inner {
  // height: 401px;
  position: absolute;
  right: 0;
  top: 43px;
  outline: none;
  width: 100%;
  border-radius: 3px;
  box-shadow: 0 3px 20px 0 rgba(0, 0, 0, 0.1);
  border: solid 1px #efefef;
  background-color: #ffffff;
  div {
    .boxItemNotify {
      // font-weight: 600;
      &:first-child {
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
      }
    }
  }
  .boxItemNotify {
    overflow: hidden;
    padding: 0.4rem;
    border-bottom: 1px solid #efefef;
    transition: 0.3s all;
    cursor: pointer;
    &:not(.text-muted):hover {
      background-color: #f7f7f7;
    }
    &.text-muted {
      cursor: not-allowed;
    }
  }
  .boxAll {
    transition: 0.3s all;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    &:hover {
      background-color: #f7f7f7;
    }
  }
}

.listItem {
  max-height: 200px default;
  overflow: auto;
}

.customInput {
  height: 40px;
  font-size: 15px;

  &:focus {
    border-color: #efefef;
  }
}

.customBackground {
  background-color: #f7f7f7;
}

.idolsItem {
  padding: 10px 0;
  border-bottom: 1px solid #efefef;
}

@media screen and (min-width: 576.5px) {
  .btnDropdown {
    height: 40px;
  }
}

@media (max-width: map-get($grid-breakpoints, 'md')) {
  .modal-inner {
    width: 100% !important;
  }
  .customBackground {
    background-color: #fff !important;
  }
}

@media (max-width: map-get($grid-breakpoints, 'sm')) {
  // .listItem {
  //   max-height: none;
  // }

  #searchIdols {
    position: sticky;
    top: 0;
    left: 0;
  }
}
</style>
