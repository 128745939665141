import { Axios } from '../common/config';
export const BankAppService = {
  getAllBank() {
    return Axios.get('/banks').then((resp) => {
      return resp;
    });
  },
  getAllProvince() {
    return Axios.get('/provinces').then((resp) => {
      return resp;
    });
  }
};

export default BankAppService;
