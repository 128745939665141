<template>
  <b-modal
    headerClass="border-bottom-0"
    modal-class="modalVerify modalCustomMobile"
    body-class="pt-0"
    :content-class="{ loadingModal: isLoading, 'px-lg-1': true }"
    hide-footer
    v-model="modalLoginData"
    @hide="onCloseModal()"
    centered
    :no-close-on-backdrop="isLoading"
    :return-focus="'xxx'"
  >
    <template v-slot:modal-title>
      <p class="font-20 color-black-1">{{ title }}</p>
    </template>
    <div class="modalChooseFastContent">
      <p class="mb-4 text-center">
        {{ titleDescription }}
      </p>
      <div>
        <form ref="form" v-on:keyup.enter="handlerVerify()">
          <b-form-group class="position-relative">
            <b-form-input
              autofocus
              v-on:keyup.enter="handlerVerify()"
              :type="typePassword"
              v-model="password"
              data-vv-as="Mật khẩu"
              :class="{ input: true, 'is-danger': errors.has('passwordUser') }"
              v-validate="'required|min:8|max:16'"
              id="passwordInput"
              name="passwordUser"
              aria-describedby="password-help-block"
            ></b-form-input>
            <span v-show="errors.has('passwordUser')" class="is-danger-text">{{ errors.first('passwordUser') }}</span>
            <UIShowPassword @handlerShowPassword="handlerShowPassword" :typePassword="typePassword"> </UIShowPassword>
          </b-form-group>
          <button
            @click.prevent="handlerConfirm"
            :disabled="isLoading"
            type="submit"
            class="btn w-100 text-uppercase mb-lg-2 btnCheck mt-2"
          >
            {{ titleBtn }}
          </button>
        </form>
      </div>
    </div>
  </b-modal>
</template>
<script>
import AccountService from '../api/account';
import UIShowPassword from '../components/UI/UIShowPassword';
export default {
  components: {
    UIShowPassword
  },
  props: ['modalVerify', 'title', 'titleBtn', 'titleDescription'],
  data() {
    return {
      password: '',
      timerWaiting: null,
      isLoading: false,
      passwordCheck: '',
      typePassword: 'password'
      // listItem: []
    };
  },
  computed: {
    modalLoginData: {
      get() {
        return this.modalVerify;
      },
      set(newVal) {
        this.$emit('updateModalVerify', newVal);
      }
    },
    isSaveReceiver() {
      return this.$store.getters.getterIsSaveReceiver;
    }
  },

  watch: {
    modalLoginData: function(newVal) {
      this.handleToggleModal(newVal);
    }
  },
  methods: {
    handlerShowPassword(event) {
      this.typePassword = event;
    },
    onCloseModal() {
      this.typePassword = 'password';
    },
    handlerConfirm() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          // if (this.password === this.passwordCheck) {
          //   this.errors.add({
          //     field: 'passwordUser',
          //     msg: 'Mật khẩu không đúng'
          //   });
          //   return;
          // }
          this.isLoading = true;

          let data = { password: this.password };
          if (this.timerWaiting) {
            clearTimeout(this.timerWaiting);
          }
          this.timerWaiting = setTimeout(() => {
            //thêm data getterIsSaveReceiver từ store để gửi lên server
            AccountService.postVerifyifyPassword(data)
              .then((resp) => {
                if (resp.status === 204) {
                  this.$store.commit('setInputPasswordAgain', this.password);
                  this.password = '';
                  // this.$store.commit('setListTimeDialOverviewForReal', null);
                  // this.$store.commit('setListTicketOverviewForReal', null);
                  this.modalLoginData = false;
                  // this.passwordCheck = this.password;

                  this.$emit('updateVerifySuccess', { data: 'actived' });
                }
              })
              .catch((e) => {
                this.$emit('updateVerifySuccess', { data: 'notActived' });
                let code = e.response.data.data.code;
                if (code === '002.001.028') {
                  this.errors.add({
                    field: 'passwordUser',
                    msg: 'Mật khẩu không đúng'
                  });
                }
              })
              .finally(() => {
                this.isLoading = false;
              });
          }, 1000);
        }
      });
    },
    handlerVerify() {}
  }
};
</script>
<style lang="scss" scoped>
.btnCheck {
  border-radius: 3px;
  background-color: $primaryColor;
  color: #fff;
  padding: 0.45rem 1.5rem;
}
</style>
